<template>
  <v-card 
    @drop.prevent="onDrop($event)" 
    @dragover.prevent="dragover = true" 
    @dragenter.prevent="dragover = true" 
    @dragleave.prevent="dragover = false"
    :class="active ? 'active' : 'activent'" min-width="800" max-width="800" style="overflow: hidden;">
    <div class="d-flex align-center">
      <v-card-title style="font-size: 1.45rem !important" class="pa-1">
        {{ hueco.lugar }}
      </v-card-title> 
      <span class="align-center pb-0" style="font-size: 1.3rem !important"> - 
        {{ hueco.horario }}
      </span>
      <v-spacer />
      <v-chip class="d-flex mr-2" label :color="hueco.colorTipoEvento" outlined>
        <v-icon small left>{{ hueco.iconTipoEvento }}</v-icon>
        {{ hueco.tipo }} ({{ hueco.nEventos }})
      </v-chip>
      <v-spacer />
      <v-btn fab x-small class="me-2" color="info" @click="imprimir()">
        <v-icon>mdi-printer-outline</v-icon>
      </v-btn>
      <v-checkbox v-model="coste.rentabilidad" hide-details="" class="my-0"></v-checkbox>
      <v-spacer />
      <v-btn color="primary" @click="active = !active" class="me-5" style="justify-self: end;" x-small><v-icon>{{ active ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
    </div>
    <v-divider />
    <v-row no-gutters>
      <v-col class="my-5 d-flex align-center">
        <v-chip class="v-chip--active">
          <v-icon left>mdi-human-male</v-icon>
          <span style="font-size: 1.2rem">{{ hueco.nAdultos }}</span>
        </v-chip>
        <v-chip class="v-chip--active">
          <v-icon left>mdi-human-child</v-icon>
          <span style="font-size: 1.2rem">{{ hueco.nNinos }}</span>
        </v-chip>
      </v-col>
    </v-row>
        <v-row class="ma-0">
          <v-tabs v-model="tab_details" vertical style="max-width: 160px;border-right: 1px solid rgba(255, 255, 255, 0.12);" class="me-2">
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="0" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD'])">RENTABILIDAD</v-tab>
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="1" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['EVENTOS'])"><v-badge dot color="error" v-if="badgeMinuta">MINUTA</v-badge><template v-else>MINUTA</template></v-tab>
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="2" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['EVENTOS'])">COSTES<br>EXPLOTACIÓN</v-tab>
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="3" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD'])"><v-badge dot color="error" v-if="badgeEquipo">COSTES<br>EQUIPO</v-badge><template v-else>COSTES<br>EQUIPO</template></v-tab>
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="4" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD'])"><v-badge dot color="error" v-if="badgeFnB">COSTES<br>F&B</v-badge><template v-else>COSTES<br>F&B</template></v-tab>
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="5" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['EVENTOS'])"><v-badge dot color="error" v-if="badgeCostesAlq">COSTES ALQUILER<br>Y COMISIONES</v-badge><template v-else>COSTES ALQUILER<br>Y COMISIONES</template></v-tab>
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="6" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['EVENTOS'])"><v-badge dot color="error" v-if="badgeCostesCat">COSTES<br>CATERING</v-badge><template v-else>COSTES<br>CATERING</template></v-tab>
            <v-tab style="padding: 8px;" class="justify-start text-left" :value="7" dense v-if="!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD'])"><v-badge dot color="error" v-if="badgeCostesGen">COSTES<br>GENERALES</v-badge><template v-else>COSTES<br>GENERALES</template></v-tab>
          </v-tabs>
        <v-tabs-items
          style="font-size: 0.9rem;flex-grow: 1;"
          class="py-1"
          v-model="tab_details"
        >
          <v-tab-item :value="0">
            <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h3 style="color: var(--v-primary-base)">TOTAL VENTAS</h3>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h3 style="color: var(--v-primary-base)">
                    {{ Math.round(+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }} 
                  </h3>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h3 style="color: var(--v-primary-base)">
                    100%
                  </h3>
                </v-col>
              </v-row>
              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h3 style="color: var(--v-primary-base)">BENEFICIO</h3>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h3 style="color: var(--v-primary-base)">
                    {{ Math.round(((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) - (+((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
                      +
                      +totalOce
                      +
                      +(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision)
                      +
                      +totalCatering
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100))).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                  </h3>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h3 style="color: var(--v-primary-base)">
                    {{ Math.round(((((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) - (+((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
                      +
                      +totalOce
                      +
                      +(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision)
                      +
                      +totalCatering
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100))) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                  </h3>
                </v-col>
              </v-row>
            <v-divider class="my-2" style="border: 2px solid var(--v-secondary-base)"></v-divider>
              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h3 style="color: var(--v-primary-base)">TOTAL COSTES (s/ ventas)</h3>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h3 style="color: var(--v-primary-base)">
                    {{ 
                      Math.round(+((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
                      +
                      +totalOce
                      +
                      +(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision)
                      +
                      +totalCatering
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
                    }}
                  </h3>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h3 style="color: var(--v-primary-base)">
                    {{ Math.round(
                      +(((((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100))) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))) * 100).toFixed(2)
                      +
                      +((Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100)
                      +
                      +(totalOce / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100)
                      +
                      +((+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100)
                      +
                      +((totalCatering / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))) * 100)
                      +
                      +((Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100)
                      ).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
                    }}%
                  </h3>
                </v-col>
              </v-row>

              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h5>EQUIPO</h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ 
                      Math.round((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
                    }}
                  </h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ 
                      Math.round(((((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100))) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
                    }}%
                  </h5>
                </v-col>
              </v-row>
              
              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h5>F&B</h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                  </h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round((Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                  </h5>
                </v-col>
              </v-row>
              
              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h5>O.C.E</h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round(totalOce).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                  </h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round(totalOce / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                  </h5>
                </v-col>
              </v-row>
              
              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h5>ALQUILERES</h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                  </h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round((+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                  </h5>
                </v-col>
              </v-row>
              
              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h5>CATERING</h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round(totalCatering).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                  </h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round((totalCatering / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                  </h5>
                </v-col>
              </v-row>
              
              <v-row class="align-centrer my-2">
                <v-col class="py-0" cols="12" sm="6">
                  <h5>GASTOS GENERALES</h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                  </h5>
                </v-col>
                <v-col class="py-0" cols="12" sm="3">
                  <h5>
                    {{ Math.round((Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                  </h5>
                </v-col>
              </v-row>
            <v-divider class="my-2" style="border: 2px solid var(--v-secondary-base)"></v-divider>
            <AuxInput class="mx-2 no-gutters" title="ADULTOS">
              {{ (hueco.nAdultos).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="NIÑOS">
              {{ (hueco.nNinos).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="PM PERSONA">
              {{ Math.round((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) / Math.max(hueco.nAdultos,1)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
            </AuxInput>

          </v-tab-item>
          <v-tab-item :value="1">
            <v-row class="ma-1 align-center">
              <v-col cols="12" sm="5">
                <h3 style="color: var(--v-primary-base)">TOTAL VENTAS</h3>
              </v-col>
              <v-col cols="12" sm="7">
                <h3 style="color: var(--v-primary-base)">
                  {{ (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </h3>
              </v-col>
            </v-row>
            <AuxInput class="mx-2 no-gutters" title="VENTAS F&B">
              <v-text-field
                filled
                v-model="coste.datos.minuta.ventasFnB"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="ALQUILERES ESPACIO">
              <v-text-field
                filled
                v-model="coste.datos.minuta.alquileresEspacio"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="SERVICIO CATERING">
              <v-text-field
                filled
                v-model="coste.datos.minuta.servicioCatering"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="SERVICIOS EXTERNOS">
              <v-text-field
                filled
                v-model="coste.datos.minuta.serviciosExternos"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
          </v-tab-item>
          <v-tab-item :value="2">
            <v-row class="ma-1 align-center py-0 my-0">
              <v-col cols="12" sm="5" class="ps-0">
                <h3 style="color: var(--v-primary-base)">TOTAL OCE</h3>
              </v-col>
              <v-col cols="12" sm="7">
                <h3 style="color: var(--v-primary-base)">{{ Math.round(totalOce).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}</h3>
              </v-col>
            </v-row>
            <v-divider></v-divider>
              <h3><b style="color: var(--v-primary-base)">CUESTIONARIO</b></h3>
              <v-checkbox
                v-for="(item, index) in coste.datos.cuestionario.fijo"
                :label="item.title"
                :key="index"
                v-model="item.aplica"
                @change="reduceVal() + reduceVal()"
                color="primary"
                class="my-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-for="(item, index) in coste.datos.cuestionario.variable"
                :label="item.title"
                :key="index+10"
                v-model="item.aplica"
                @change="reduceVal() + reduceVal()"
                color="primary"
                hide-details
                class="my-0"
              ></v-checkbox>
              <v-divider></v-divider>
              <h3><b style="color: var(--v-primary-base)">SERVICIOS EXTERNOS</b></h3>
              <template v-for="(item, i) in coste.datos.cuestionario.fijo" v-if="item.aplica && item.title != 'SERVICIO WDS'">
                <AuxInput class="mx-2 no-gutters" :title="item.title">
                  {{ Math.round(item.precio).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </AuxInput>
              </template>
              <template v-for="(item, i) in coste.datos.cuestionario.variable" v-if="item.aplica">
                <AuxInput class="mx-2 no-gutters" :title="item.title">
                  {{ Math.round(+item.precio * (+hueco.nAdultos + +hueco.nNinos)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </AuxInput>
              </template>
              <AuxInput class="mx-2 no-gutters" title="LENCERÍA Y QUÍMICOS">
                {{ Math.round(2.5 * (+hueco.nAdultos + +hueco.nNinos)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
              </AuxInput>
              <AuxInput class="mx-2 no-gutters" title="ROTURAS Y REPOSICIÓN">
                {{ Math.round(Math.round((+(coste.datos.minuta.ventasFnB * 0.01)  + Number.EPSILON) * 100) / 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
              </AuxInput>
              <AuxInput class="mx-2 no-gutters" title="COMIDA PERSONAL">
                {{ Math.round((+hueco.nAdultos + +hueco.nNinos) * 0.4).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
              </AuxInput>
              <v-divider></v-divider>
              <h3><b style="color: var(--v-primary-base)">OTROS EXTRAS</b></h3>
              <template v-for="(item, i) in coste.datos.otrosExtras">
                <AuxInput class="mx-2 no-gutters"
                  :title="item.title"
                >
                  <v-text-field
                    filled
                    v-model="item.precio"
                    dense
                    @input="reduceVal() + reduceVal()"
                    hide-details
                    class="v-superdense-input"
                    append-icon="mdi-trash-can-outline"
                    @click:append="delOce(i) + reduceVal()"
                  />
                </AuxInput>
              </template>
              <div style="width: 100%" class="d-flex justify-center ms-n1">
                <v-btn @click="addInputExtras" rounded style="width: 90%" class="mx-auto" size="x-large"><v-icon>mdi-playlist-plus</v-icon></v-btn>
              </div>
          </v-tab-item>
          <v-tab-item :value="3">
            <v-row class="ma-1 align-center">
              <v-col class="py-0" cols="12" sm="6">
                <h3>VENTAS F&B</h3>
              </v-col>
              <v-col class="py-0" cols="12" sm="3">
                <h3>
                  {{ Math.round(+coste.datos.minuta.ventasFnB).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }} 
                </h3>
              </v-col>
              <v-col class="py-0 text-end" cols="12" sm="3">
                <h3>
                  100% 
                </h3>
              </v-col>
            </v-row>
            <v-row class="ma-1 align-center">
              <v-col class="py-0" cols="12" sm="6">
                <h3>VENTAS SERVICIO CATERING</h3>
              </v-col>
              <v-col class="py-0" cols="12" sm="3">
                <h3>
                  {{ Math.round(+coste.datos.minuta.servicioCatering).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }} 
                </h3>
              </v-col>
              <v-col class="py-0 text-end" cols="12" sm="3">
                <h3>
                  100% 
                </h3>
              </v-col>
            </v-row>
            <v-row class="ma-1 align-center">
              <v-col class="py-0" cols="12" sm="6">
                <h3>COSTE EQUIPO</h3>
              </v-col>
              <v-col class="py-0" cols="12" sm="3">
                <h3>
                  {{ Math.round(+(Math.round((+coste.datos.minuta.ventasFnB * (12 / 100)  + Number.EPSILON) * 100) / 100) + +(Math.round((+coste.datos.minuta.servicioCatering * (35 / 100)  + Number.EPSILON) * 100) / 100) + +(+coste.datos.equipo.camareros + +coste.datos.equipo.cocineros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.otros)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </h3>
              </v-col>
              <v-col class="py-0 text-end" cols="12" sm="3">
                <h3>
                  {{ Math.round((+(+(Math.round((+coste.datos.minuta.ventasFnB * (12 / 100)  + Number.EPSILON) * 100) / 100) + +(Math.round((+coste.datos.minuta.servicioCatering * (35 / 100)  + Number.EPSILON) * 100) / 100) + +(+coste.datos.equipo.camareros + +coste.datos.equipo.cocineros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.otros)) / +(Math.max(+coste.datos.minuta.ventasFnB, 1))) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                </h3>
              </v-col>
            </v-row>
            <v-row class="ma-1 align-center">
              <v-col class="py-0" cols="12" sm="6">
                <h3>COSTE EQUIPO FIJO</h3>
              </v-col>
              <v-col class="py-0" cols="12" sm="3">
                <h3>
                  {{ Math.round(Math.round((+coste.datos.minuta.ventasFnB * (12 / 100)  + Number.EPSILON) * 100) / 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </h3>
              </v-col>
              <v-col class="py-0 text-end" cols="12" sm="3">
                <h3>
                  {{ Math.round(+(+(+Math.round((+coste.datos.minuta.ventasFnB * (12 / 100)  + Number.EPSILON) * 100) / 100) * 100) / Math.max(coste.datos.minuta.ventasFnB, 1)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                </h3>
              </v-col>
            </v-row>
            <v-row class="ma-1 align-center">
              <v-col class="py-0" cols="12" sm="6">
                <h3>COSTE CATERING</h3>
              </v-col>
              <v-col class="py-0" cols="12" sm="3">
                <h3>
                  {{ Math.round(Math.round((+coste.datos.minuta.servicioCatering * (35 / 100)  + Number.EPSILON) * 100) / 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </h3>
              </v-col>
              <v-col class="py-0 text-end" cols="12" sm="3">
                <h3>
                  {{ Math.round(+(+(+Math.round((+coste.datos.minuta.servicioCatering * (35 / 100)  + Number.EPSILON) * 100) / 100) * 100) / Math.max(coste.datos.minuta.ventasFnB, 1)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                </h3>
              </v-col>
            </v-row>
            <v-row class="ma-1 align-center">
              <v-col class="py-0" cols="12" sm="6">
                <h3>COSTE VARIABLE</h3>
              </v-col>
              <v-col class="py-0" cols="12" sm="3">
                <h3>
                  {{ Math.round(+coste.datos.equipo.camareros + +coste.datos.equipo.cocineros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.otros).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </h3>
              </v-col>
              <v-col class="py-0 text-end" cols="12" sm="3">
                <h3>
                  {{ Math.round(+(+coste.datos.equipo.camareros + +coste.datos.equipo.cocineros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.otros) * 100 / Math.max(coste.datos.minuta.ventasFnB, 1)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                </h3>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <AuxInput class="mx-2 ms-10 no-gutters" title="COSTE TOTAL CAMAREROS">
              <v-text-field
                filled
                v-model="coste.datos.equipo.camareros"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 ms-10 no-gutters" title="COSTE TOTAL BODEGUEROS">
              <v-text-field
                filled
                v-model="coste.datos.equipo.bodegueros"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 ms-10 no-gutters" title="COSTE TOTAL COCINEROS">
              <v-text-field
                filled
                v-model="coste.datos.equipo.cocineros"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 ms-10 no-gutters" title="COSTE TOTAL PLONGE">
              <v-text-field
                filled
                v-model="coste.datos.equipo.senoras"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 ms-10 no-gutters" title="COSTE TOTAL OTROS">
              <v-text-field
                filled
                v-model="coste.datos.equipo.otros"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
          </v-tab-item>
          <v-tab-item :value="4">
            <AuxInput class="mx-2 no-gutters" title="% COSTE FOOD - FIJO">
              {{ Math.round(coste.datos.FnB.pntCosteFoodFijo).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="% COSTE BEBERAGE - FIJO">
              {{ Math.round(coste.datos.FnB.pntCosteBeberageFijo).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="% COSTE FOOD - VARIABLE">
              <v-text-field
                filled
                v-model="coste.datos.FnB.pntCosteFood"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="% COSTE BEBERAGE - VARIABLE">
              <v-text-field
                filled
                v-model="coste.datos.FnB.pntCosteBeberage"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <v-row class="ma-1 align-center">
              <v-col class="pb-0" cols="12" sm="7">
                <h3 style="color: var(--v-primary-base)"><b>TOTAL RATIO COSTE F&B</b></h3>
              </v-col>
              <v-col class="pb-0" cols="12" sm="5">
                <h3 style="color: var(--v-primary-base)">
                  {{ Math.round(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
                </h3>
              </v-col>
            </v-row>
            <v-row class="ma-1 align-center">
              <v-col class="py-0" cols="12" sm="7">
                <h3 style="color: var(--v-primary-base)"><b>TOTAL COSTE F&B</b></h3>
              </v-col>
              <v-col class="py-0" cols="12" sm="5">
                <h3 style="color: var(--v-primary-base)">
                  {{ Math.round(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
                </h3>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="5">
            <AuxInput class="mx-2 no-gutters"
              title="TOTAL ALQUILER"
            >
              {{ Math.round(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters"
              title="ALQUILER"
            >
              {{ Math.round(+coste.datos.minuta.alquileresEspacio).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters"
              title="COMISIÓN (€)"
            >
              <v-text-field
                filled
                v-model="coste.datos.alquiler.comision"
                dense
                hide-details
                class="v-superdense-input"
                @input="reduceVal"
              />
            </AuxInput>
          </v-tab-item>
          <v-tab-item :value="6">
            <v-row class="ma-1 align-center py-0 my-0">
              <v-col cols="12" sm="5" class="ps-0">
                <h3 style="color: var(--v-primary-base)">TOTAL CATERING</h3>
              </v-col>
              <v-col cols="12" sm="7">
              <h3 style="color: var(--v-primary-base)">{{ Math.round(totalCatering).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}</h3>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <template v-for="(item, i) in coste.datos.costesCatering">
              <AuxInput class="mx-2 no-gutters"
                :title="item.title"
              >
                <v-text-field
                  @input="reduceVal"
                  filled
                  v-model="item.precio"
                  dense
                  hide-details
                  class="v-superdense-input"
                  append-icon="mdi-trash-can-outline"
                  @click:append="delCat(i)"
                />
              </AuxInput>
            </template>
            <div style="width: 100%" class="d-flex justify-center ms-n1">
              <v-btn @click="addInputCatering" rounded style="width: 90%" class="mx-auto" size="x-large"><v-icon>mdi-playlist-plus</v-icon></v-btn>
            </div>
          </v-tab-item>
          <v-tab-item :value="7">
            <AuxInput class="mx-2 no-gutters" title="% COSTE - FIJO">
              {{ Math.round(coste.datos.general.pntCosteFijo).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="% COSTE - VARIABLE">
              <v-text-field
                filled
                v-model="coste.datos.general.pntCosteVariable"
                @input="reduceVal()"
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="TOTAL RATIO GASTOS GENERALES">
              {{ Math.round(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}%
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="TOTAL GASTOS GENERALES">
              {{ Math.round(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}
            </AuxInput>
          </v-tab-item>
        </v-tabs-items>
      </v-row>
      <NewFieldDialog ref="newField" />
      <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>

export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
    NewFieldDialog: () => import("@/components/NewFieldDialog.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue")
  },
  props: {
    hueco: Object,
    date: String,
  },
  data() {
    return {
      active: false,
      camareros: [7, 19, 13, 17, 9, 12, 14, 11, 15, 1],
      cocineros: [2],
      senoras: [18],
      bdg: [4],
      equipo : [],
      plan: [],
      mercenarios: [],
      total: 0,
      margenNeto: 0,
      badgeMinuta: true,
      badgeFnB: true,
      badgeEquipo: true,
      badgeOce: true,
      badgeCostesAlq: true,
      badgeCostesCat: true,
      badgeCostesGen: true,
      totalCatering: 0,
      totalOce: 0,
      totalEquipo: 0,
      acc: 0,
      tab_details: 0,
      iva: 10,
      coste: {},
      datosModel: {
        general:{
          pntCosteFijo: 14,
          pntCosteVariable: null,
        },
        minuta: {
          ventasFnB: null,
          alquileresEspacio: null,
          servicioCatering: null,
          serviciosExternos: null,
        },
        cuestionario: {
          fijo: [
            {
              title: "MEGAFONÍA - ENLACE CIVIL",
              aplica: false,
              precio: 100,
            },
            {
              title: "SERVICIO DJ",
              aplica: false,
              precio: 500,
            },
            {
              title: "ANIMACIÓN INFANTIL",
              aplica: false,
              precio: 120,
            },
            {
              title: "SERVICIO WDS",
              aplica: false,
              precio: 0,
            },
            {
              title: "SGAE Y AGEDI",
              aplica: false,
              precio: 220,
            },
          ],
          variable: [
            {
              title: "DECORACIÓN FLORAL",
              aplica: false,
              precio: 2.5,
            },
            {
              title: "MINUTAS CONMEMORATIVAS",
              aplica: false,
              precio: 1.5,
            }
          ],
        },
        otrosExtras: [],
        equipo: {
          camareros: null,
          cocineros: null,
          senoras: null,
          bodegueros: null,
          otros: null
        },
        FnB:{
          pntCosteFoodFijo: 24,
          pntCosteBeberageFijo: 7,
          pntCosteFood: null,
          pntCosteBeberage: null,
        },
        oce:[
          {
            title: "LENCERÍA",
            aplica: true,
            precio: 1.5,
          },
          {
            title: "ROTURAS Y REPOSICIÓN",
            aplica: true,
            precio: 1,
          },
          {
            title: "COMIDA PERSONAL",
            aplica: true,
            precio: 0.4,
          },
        ],
        alquiler: {
          comision: null
        },
        costesCatering: [
          {
            title: "SERVICIO FURGONETA",
            precio: null
          },
          {
            title: "SERVICIO TAXI",
            precio: null
          },
          {
            title: "SERVICIO AUTOBÚS",
            precio: null
          },
          {
            title: "GASOLINAS",
            precio: null
          },
          {
            title: "GRUPO ELECTRÓGENO",
            precio: null
          },
          {
            title: "COMIDA/BEBIDA EXTRA",
            precio: null
          },
          {
            title: "PERNOCTACIONES",
            precio: null
          },
          {
            title: "ROTURAS EXTRAS",
            precio: null
          },
        ],
      }
    }
  },
  computed: {
    auxModel: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  beforeMount(){
    this.coste = this.hueco;
    this.coste.datos = Object.assign({}, this.datosModel, this.hueco.datos);
  },
  methods:{
    onDrop(file){
      this.dragover = false;
      if (file.dataTransfer.files.length > 1) {
        this.$root.$emit("snack", "No se pueden subir varios archivos a la vez");
      }
      else{
        if (file.dataTransfer.files[0].name.split(".")[file.dataTransfer.files[0].name.split(".").length-1] != "csv") this.$root.$emit("snack", "Solo se admiten archivos CSV");
        else this.handleFileSelect(file.dataTransfer.files[0])
      }
    },
    handleFileSelect(event) {
      if (!event) return
      const file = event;
      const reader = new FileReader();

      const self = this
      reader.onload = function(event) {
        try {
          const csv = event.target.result;
          self.csvToArray(csv);
        } catch (error) {
          console.error(error);
        }
      };
      reader.readAsText(file);
    },
    async csvToArray(csv) {
      var rows = csv.split('\r\n');
      rows.forEach((row) => {
        var [key, nada2, nada3, val] = row.split(";")
        if (key == "VENTAS F&B") {
          val = val.replaceAll("€","").replaceAll(" ", "").replaceAll(",", "")
          if (val == "-") val = 0
          this.coste.datos.minuta.ventasFnB = parseFloat(val)
        }
        else if (key == "ALQUILERES ESPACIOS") {
          val = val.replaceAll("€","").replaceAll(" ", "").replaceAll(",", "")
          if (val == "-") val = 0
          this.coste.datos.minuta.alquileresEspacio = parseFloat(val)
        }
        else if (key == "SERVICIO CATERING y ESTRUCTURA") {
          val = val.replaceAll("€","").replaceAll(" ", "").replaceAll(",", "")
          if (val == "-") val = 0
          this.coste.datos.minuta.servicioCatering = parseFloat(val)
        }
        else if (key == "SERVICIOS EXTERNOS") {
          val = val.replaceAll("€","").replaceAll(" ", "").replaceAll(",", "")
          if (val == "-") val = 0
          this.coste.datos.minuta.serviciosExternos = parseFloat(val)
        }
      });
    },
    async addInputExtras(){
      const confirmation = await this.$refs.newField.open(
        `Añadir coste extraordinario`,
        `Introduce el nombre del campo.`,
        { color: "primary" }
      );
      if (!confirmation) return;
      this.coste.datos.otrosExtras.push({ title : confirmation, precio : null});
      this.calculateBadges();
    },
    async addInputCatering(){
      const confirmation = await this.$refs.newField.open(
        `Añadir coste catering`,
        `Introduce el nombre del campo.`,
        { color: "primary" }
      );
      if (!confirmation) return;
      this.coste.datos.costesCatering.push({ title : confirmation, precio : null});
      this.calculateBadges();
    },
    reduceVal(){
      this.totalCatering = this.coste.datos.costesCatering.reduce((acc, v) => (typeof acc === 'object') ? acc.precio += +v.precio : +acc + +v.precio, 0);
      this.totalOce = 0;
      this.totalOce += this.coste.datos.cuestionario.fijo.filter(n => n.aplica == true).reduce((acc, v) => acc + v.precio, 0);
      this.totalOce += this.coste.datos.cuestionario.variable.filter(n => n.aplica == true).reduce((acc, v) => acc + (v.precio * (this.hueco.nNinos + this.hueco.nAdultos)), 0);
      this.coste.datos.otrosExtras.forEach(coste => {
        this.totalOce += +coste.precio;
      });
      this.totalOce += ((Math.round((+(this.coste.datos.minuta.ventasFnB * (1 / 100)) + Number.EPSILON) * 100) / 100));
      this.totalOce += (2.5 * (+this.hueco.nAdultos + +this.hueco.nNinos));
      this.totalOce += ((+this.hueco.nAdultos + +this.hueco.nNinos) * 0.4);
      this.calculateBadges();
    },
    async delOce(i){
      const confirmation = await this.$refs.confirm.open(
        `¿Eliminar elemento?`,
        `Esta acción es irreversible.`,
        { color: "primary" }
      );
      if (!confirmation) return;
      this.coste.datos.otrosExtras.splice(i,1);
      this.reduceVal();
      this.calculateBadges();
    },
    async delCat(i){
      const confirmation = await this.$refs.confirm.open(
        `¿Eliminar elemento?`,
        `Esta acción es irreversible.`,
        { color: "primary" }
      );
      if (!confirmation) return;
      this.coste.datos.costesCatering.splice(i,1);
      this.reduceVal();
      this.calculateBadges();
    },
    calculateBadges(){
      this.badgeMinuta = false;
      if (Object.values(this.coste.datos.minuta).filter(i => i == null).length > 0 || Object.values(this.coste.datos.minuta).filter(i => i.length == 0).length > 0 || Object.values(this.coste.datos.minuta).filter(i => isNaN(i)).length > 0) this.badgeMinuta = true;

      this.badgeFnB = false;
      if (Object.values(this.coste.datos.FnB).filter(i => i == null).length > 0 || Object.values(this.coste.datos.FnB).filter(i => i.length == 0).length > 0 || Object.values(this.coste.datos.FnB).filter(i => isNaN(i)).length > 0) this.badgeFnB = true;

      this.badgeEquipo = false;
      if (Object.values(this.coste.datos.equipo).filter(i => i == null).length > 0 || Object.values(this.coste.datos.equipo).filter(i => i.length == 0).length > 0 || Object.values(this.coste.datos.equipo).filter(i => isNaN(i)).length > 0 ) this.badgeEquipo = true;
      
      this.badgeOce = false;
      (Object.values(this.coste.datos.cuestionario).filter(i => i.aplica == true)).forEach(i => {
        if (i.precio.length == 0  || i.precio == 0  || i.precio == null || isNaN(i.precio)) this.badgeOce = true;
      });

      if(this.coste.datos.otrosExtras.length > 0){
        if (Object.values(this.coste.datos.otrosExtras).filter(i => i.precio == null).length > 0 || Object.values(this.coste.datos.otrosExtras).filter(i => i.precio.length == 0).length > 0 || Object.values(this.coste.datos.otrosExtras).filter(i => isNaN(i.precio)).length > 0) this.badgeOce = true;
      }

      this.badgeCostesAlq = false;
      if(this.coste.datos.alquiler.comision === null || this.coste.datos.alquiler.comision.length === 0 || isNaN(this.coste.datos.alquiler.comision)) this.badgeCostesAlq = true;

      if(this.coste.datos.costesCatering.length > 0){
        this.badgeCostesCat = false;
        if (Object.values(this.coste.datos.costesCatering).filter(i => i.precio == null).length > 0 || Object.values(this.coste.datos.costesCatering).filter(i => i.precio.length == 0).length > 0 || Object.values(this.coste.datos.costesCatering).filter(i => isNaN(i.precio)).length > 0) this.badgeCostesCat = true;
      }

      this.badgeCostesGen = false;
      if (this.coste.datos.general.pntCosteVariable == null || this.coste.datos.general.pntCosteVariable.length == 0 || isNaN(this.coste.datos.general.pntCosteVariable)) this.badgeCostesGen = true;
    },
    async checkCostes(){
      const { data } = await axios({
        url: `/horas/equipo`, params : { fecha : (new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getFullYear() + '-' + ('0'+(new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getMonth()).slice(-2) + '-' + ('0'+(new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getDate()).slice(-2), ck: this.hueco.custom_key}
      });
      this.equipo = data
      const res = await axios({
        url: `/horas/plan`, params : { fecha : (new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getFullYear() + '-' + ('0'+(new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getMonth()).slice(-2) + '-' + ('0'+(new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getDate()).slice(-2), ck: this.hueco.custom_key}
      });
      this.plan = res.data
      const res2 = await axios({
        url: `/horas/mercenarios`, params : { fecha : (new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getFullYear() + '-' + ('0'+(new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getMonth()).slice(-2) + '-' + ('0'+(new Date(this.date.split('/')[2], this.date.split('/')[1], this.date.split('/')[0])).getDate()).slice(-2), ck: this.hueco.custom_key}
      });
      this.mercenarios = res2.data
      // NO SON IGUALES
      if (this.plan.length != this.equipo.length){
        var exclude = []
        // eliminar antiguos
        this.plan.forEach(p => {
          if (this.equipo.filter(e => e.idUnidadEquipo == p.idUnidadEquipo).length == 0) exclude.push(p.idUnidadEquipo);
        });
        this.plan = this.plan.filter(p => !exclude.includes(p.idCategoriaEquipo))
        //añadir nuevos
        this.equipo.forEach(e => {
          if (this.plan.filter(p => p.idUnidadEquipo == e.idUnidadEquipo).length == 0) this.plan.push({ idUnidadEquipo: e.idUnidadEquipo, idCategoriaEquipo: e.idCategoriaEquipo, nombre: e.nombre, apellidos: e.apellidos, entrada: 0, salida: 0, pHora: (e.pEvento || 0), pHoraAux: 'Ev', kms: 0, varios: 0});
        }); 
        this.plan.sort((a, b) => a.idCategoriaEquipo < b.idCategoriaEquipo)
      }
      var bdg = 0
      this.plan.filter(p => this.bdg.includes(this.equipo.filter(e => e.idUnidadEquipo == p.idUnidadEquipo)[0].idCategoriaEquipo)).forEach(c => {
        bdg += ((((parseFloat(c.entrada || 0) > parseFloat(c.salida || 0) ? (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0)) + 24 : (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0))) * parseFloat(c.pHora || 0)) + parseFloat(c.varios || 0) + (parseFloat((c.kms || 0)*.23))) * 1.43)
      });
      if (!this.coste.datos.equipo.bodegueros || this.coste.datos.equipo.bodegueros.length < 1 || this.coste.datos.equipo.bodegueros == 0) this.coste.datos.equipo.bodegueros = bdg.toFixed(2); 
      var plg = 0
      this.plan.filter(p => this.senoras.includes(this.equipo.filter(e => e.idUnidadEquipo == p.idUnidadEquipo)[0].idCategoriaEquipo)).forEach(c => {
        plg += ((((parseFloat(c.entrada || 0) > parseFloat(c.salida || 0) ? (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0)) + 24 : (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0))) * parseFloat(c.pHora || 0)) + parseFloat(c.varios || 0) + (parseFloat((c.kms || 0)*.23))) * 1.43)
      });
      if (!this.coste.datos.equipo.senoras || this.coste.datos.equipo.senoras.length < 1 || this.coste.datos.equipo.senoras == 0) this.coste.datos.equipo.senoras = plg.toFixed(2); 
      var cam = 0
      this.plan.filter(p => this.camareros.includes(this.equipo.filter(e => e.idUnidadEquipo == p.idUnidadEquipo)[0].idCategoriaEquipo)).forEach(c => {
        cam += ((((parseFloat(c.entrada || 0) > parseFloat(c.salida || 0) ? (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0)) + 24 : (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0))) * parseFloat(c.pHora || 0)) + parseFloat(c.varios || 0) + (parseFloat((c.kms || 0)*.23))) * 1.43)
      });
      this.mercenarios.forEach(c => {
        cam += ((((parseFloat(c.entrada || 0) > parseFloat(c.salida || 0) ? (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0)) + 24 : (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0))) * parseFloat(c.pHora || 0)) + parseFloat(c.varios || 0) + (parseFloat((c.kms || 0)*.23))) * 1.43)
      });
      if (!this.coste.datos.equipo.camareros || this.coste.datos.equipo.camareros.length < 1 || this.coste.datos.equipo.camareros == 0) this.coste.datos.equipo.camareros = cam.toFixed(2); 
      var coc = 0
      this.plan.filter(p => this.cocineros.includes(this.equipo.filter(e => e.idUnidadEquipo == p.idUnidadEquipo)[0].idCategoriaEquipo)).forEach(c => {
        coc += ((((parseFloat(c.entrada || 0) > parseFloat(c.salida || 0) ? (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0)) + 24 : (parseFloat(c.salida || 0) - parseFloat(c.entrada || 0))) * parseFloat(c.pHora || 0)) + parseFloat(c.varios || 0) + (parseFloat((c.kms || 0)*.23))) * 1.43)
      });
      if (!this.coste.datos.equipo.cocineros || this.coste.datos.equipo.cocineros.length < 1 || this.coste.datos.equipo.cocineros == 0) this.coste.datos.equipo.cocineros = coc.toFixed(2);
      this.calculateBadges();
      this.reduceVal();
      this.keyHelper++;
    },
    imprimir(){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>' + this.hueco.lugar + ' ' + this.hueco.horario + ' _ ' + this.hueco.tipo + '(' + this.hueco.nEventos + ') _ ' + (new Date(this.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() + ' - ' + (('0'+new Date(this.date).getDate()).slice(-2)) + ' de ' + new Date(this.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.date).getFullYear()) +'</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@7.2.96/css/materialdesignicons.min.css"><style>ul {font-size: 14px !important};</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + this.hueco.lugar + ' ' + this.hueco.horario + ' </h3><h3>' + ' ' + this.hueco.tipo + ' (' + this.hueco.nEventos + ')</div></h3><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + (new Date(this.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() + ' - ' + (('0'+new Date(this.date).getDate()).slice(-2)) + '/' + new Date(this.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.date).getFullYear()) + '</h3></div></div><hr/></div>'
      
      //rentabilidad
      var rent = v.document.createElement('div')
      rent.style = "page-break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      rent.innerHTML = "<h1 style='margin: 0px;text-align: center;border-bottom:1px solid black;background-color:rgb(135, 135, 135)'>RENTABILIDAD</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width: 100%"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr><td><b>TOTAL VENTAS</b></td><td style='color: #0086b3'><b>" + (Math.round((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>100%</b></td></tr>";
      tb.innerHTML += "<tr><td><b>BENEFICIO</b></td><td style='color: #0086b3'><b>" + (Math.round(((((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) - (+((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
        +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
        +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
        +
        +(Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
        +
        +this.totalOce
        +
        +(+this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.alquiler.comision)
        +
        +this.totalCatering
        +
        +(Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100))))) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>" + (Math.round((((((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) - (+((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
        +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
        +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
        +
        +(Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
        +
        +this.totalOce
        +
        +(+this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.alquiler.comision)
        +
        +this.totalCatering
        +
        +(Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100))) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos))) * 100)) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>";
      tb.innerHTML += "<tr style='border-top:5px solid gray;border-bottom: 1px solid gray'><td><b>TOTAL COSTES (s/ ventas)</b></td><td style='color: #0086b3'><b>" + (Math.round((+((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
        +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
        +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
        +
        +(Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
        +
        +this.totalOce
        +
        +(+this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.alquiler.comision)
        +
        +this.totalCatering
        +
        +(Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100))) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>" + (Math.round((
        +(((((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
        +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
        +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100))) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos))) * 100)
        +
        +((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100)
        +
        +(this.totalOce / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100)
        +
        +((+this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.alquiler.comision) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100)
        +
        +((this.totalCatering / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos))) * 100)
        +
        +((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100)
        )) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")  + "%</b></td></tr>";
      tb.innerHTML += "<tr><td>COSTES EXPLOTACIÓN</td><td style='color: #0086b3'>" + Math.round(this.totalOce || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round((this.totalOce / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>EQUIPO</td><td style='color: #0086b3'>" + Math.round(((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
        +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
        +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round((((((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
        +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
        +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100))) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos))) * 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>F&B</td><td style='color: #0086b3'>" + Math.round((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round(((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>ALQUILERES</td><td style='color: #0086b3'>" + Math.round((+this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.alquiler.comision) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round(((+this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.alquiler.comision) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>CATERING</td><td style='color: #0086b3'>" + (this.coste.datos.minuta.servicioCatering == 0 ? (Math.round(this.totalCatering || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")) : (Math.round((+this.totalCatering + 500) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "."))) + "</td><td style='color: #b30000'>" + Math.round((((this.coste.datos.minuta.servicioCatering == 0 ? this.totalCatering : (+this.totalCatering + 500)) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos))) * 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>GASTOS GENERALES</td><td style='color: #0086b3'>" + Math.round((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round(((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos)) * 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr style='border-top: 5px solid gray'><td>ADULTOS</td><td colspan='2'>" + Math.round(this.hueco.nAdultos || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td>NIÑOS</td><td colspan='2'>" + Math.round(this.hueco.nNinos || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td>PRECIO MEDIO PERSONA</td><td colspan='2'>" + Math.round(((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) / Math.max(this.hueco.nAdultos,1)) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      t.appendChild(tb)
      rent.appendChild(t)
      wrapper.appendChild(rent)

      //MINUTA
      var min = v.document.createElement('div')
      min.style = "page-break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      min.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid black; background-color:rgb(135, 135, 135); text-align: center'>MINUTA</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width : 100%;"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr style='border-bottom: 1px solid gray'><td><b>TOTAL VENTAS</b></td><td style='color: #0086b3'><b>" + Math.round((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'>100%</td></tr>";
      tb.innerHTML += "<tr><td>VENTAS F&B</td><td style='color: #0086b3'>" + Math.round(+this.coste.datos.minuta.ventasFnB || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round(((this.coste.datos.minuta.ventasFnB) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) || 1)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>ALQUILERES ESPACIO</td><td style='color: #0086b3'>" + Math.round(+this.coste.datos.minuta.alquileresEspacio || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + (Math.round(((this.coste.datos.minuta.alquileresEspacio) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) || 1)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")) + "%</td></tr>";
      tb.innerHTML += "<tr><td>SERVICIO CATERING</td><td style='color: #0086b3'>" + Math.round(+this.coste.datos.minuta.servicioCatering || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + (Math.round(((this.coste.datos.minuta.servicioCatering) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) || 1)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")) + "%</td></tr>";
      tb.innerHTML += "<tr><td>SERVICIOS EXTERNOS</td><td style='color: #0086b3'>" + Math.round(+this.coste.datos.minuta.serviciosExternos || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + (Math.round(((this.coste.datos.minuta.serviciosExternos) / ((+this.coste.datos.minuta.ventasFnB + +this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.minuta.servicioCatering + +this.coste.datos.minuta.serviciosExternos) || 1)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")) + "%</td></tr>";
      t.appendChild(tb)
      min.appendChild(t)
      wrapper.appendChild(min)

      //COSTES EXPLOTACIÓN
      var ce = v.document.createElement('div')
      ce.style = "page-break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      ce.innerHTML = "<h1 style='margin: 0px;text-align: center;border-bottom:1px solid black;background-color:rgb(135, 135, 135)'>COSTES EXPLOTACIÓN</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width : 100%"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr><td>VENTAS F&B</td><td style='color: #0086b3'>" + Math.round(this.coste.datos.minuta.ventasFnB || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>100%</td></tr>";
      tb.innerHTML += "<tr style='border-bottom: 1px solid gray'><td><b>TOTAL OCE</b></td><td style='color: #0086b3'><b>" + Math.round(this.totalOce || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>" + (Math.round(((this.totalOce || 0) / (this.coste.datos.minuta.ventasFnB || 1)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")) + "%</b></td></tr>";
      tb.innerHTML += "<tr><td colspan='2' style='text-align:center'><b>SERVICIOS EXTERNOS</b></td></tr>";
      this.coste.datos.cuestionario.fijo.filter(i => i.aplica && i.title != "SERVICIO WDS").forEach(item => {
        tb.innerHTML += "<tr><td>" + item.title + "</td><td style='color: #0086b3'>" + Math.round(item.precio || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      });
      this.coste.datos.cuestionario.variable.filter(i => i.aplica && i.title != "SERVICIO WDS").forEach(item => {
        tb.innerHTML += "<tr><td>" + item.title + "</td><td style='color: #0086b3'>" + Math.round(item.precio * (+this.hueco.nAdultos + +this.hueco.nNinos) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      });
      tb.innerHTML += "<tr><td>LENCERÍA Y QUÍMICOS</td><td style='color: #0086b3'>" + Math.round((2.5 * (+this.hueco.nAdultos + +this.hueco.nNinos)) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td>ROTURAS Y REPOSICIÓN</td><td style='color: #0086b3'>" + Math.round((Math.round((+(this.coste.datos.minuta.ventasFnB * 0.01).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")  + Number.EPSILON) * 100) / 100) || 0) + "</td></tr>";
      tb.innerHTML += "<tr><td>COMIDA PERSONAL</td><td style='color: #0086b3'>" + Math.round(((+this.hueco.nAdultos + +this.hueco.nNinos) * 0.4) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td colspan='2' style='text-align:center'><b>OTROS EXTRAS</b></td></tr>";
      this.coste.datos.otrosExtras.forEach(item => {
        tb.innerHTML += "<tr><td>" + item.title + "</td><td style='color: #0086b3'>" + Math.round(+item.precio || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      });
      t.appendChild(tb)
      ce.appendChild(t)
      wrapper.appendChild(ce)
      var aux = v.document.createElement('div')
      aux.setAttribute("style", "page-break-before: always !important;")
      //COSTES EQUIPO
      var eq = v.document.createElement('div')
      eq.style = "break-inside: avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      eq.innerHTML = "<h1 style='margin: 0px;text-align: center;border-bottom:1px solid black;background-color:rgb(135, 135, 135)'>COSTES EQUIPO</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width : 100%;"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr><td>VENTAS F&B</td><td style='color: #0086b3'>" + Math.round(this.coste.datos.minuta.ventasFnB || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>100%</td></tr>";
      tb.innerHTML += "<tr style='border-bottom: 1px solid gray'><td><b>COSTE EQUIPO</b></td><td style='color: #0086b3'><b>" + (Math.round(((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.otros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.cocineros) +
        +(Math.round(+(+(this.coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
        +(Math.round(+(+(this.coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")) + "</b></td><td style='color: #b30000'><b>" + Math.round(((+(+(Math.round((+this.coste.datos.minuta.ventasFnB * (12 / 100)  + Number.EPSILON) * 100) / 100) + +(Math.round((+this.coste.datos.minuta.servicioCatering * (35 / 100)  + Number.EPSILON) * 100) / 100) + +(+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.cocineros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.otros)) / +(Math.max(+this.coste.datos.minuta.ventasFnB, 1))) * 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>";
      tb.innerHTML += "<tr><td>COSTE EQUIPO FIJO</td><td style='color: #0086b3'>" + Math.round((Math.round((+this.coste.datos.minuta.ventasFnB * (12 / 100)  + Number.EPSILON) * 100) / 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round((+(+(+Math.round((+this.coste.datos.minuta.ventasFnB * (12 / 100)  + Number.EPSILON) * 100) / 100) * 100) / Math.max(this.coste.datos.minuta.ventasFnB, 1)) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>COSTE CATERING</td><td style='color: #0086b3'>" + Math.round((Math.round((+this.coste.datos.minuta.servicioCatering * (35 / 100)  + Number.EPSILON) * 100) / 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round((+(+(+Math.round((+this.coste.datos.minuta.servicioCatering * (35 / 100)  + Number.EPSILON) * 100) / 100) * 100) / Math.max(this.coste.datos.minuta.ventasFnB, 1)) ||0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>COSTE VARIABLE</td><td style='color: #0086b3'>" + Math.round((+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.cocineros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.otros) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>" + Math.round((+(+this.coste.datos.equipo.camareros + +this.coste.datos.equipo.cocineros + +this.coste.datos.equipo.senoras + +this.coste.datos.equipo.bodegueros + +this.coste.datos.equipo.otros) * 100 / Math.max(this.coste.datos.minuta.ventasFnB, 1)) ||0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr style='border-top: 5px solid gray'><td style='padding-left: 20px'>COSTE TOTAL CAMAREROS</td><td style='color: #0086b3' colspan='2'>" + Math.round(+this.coste.datos.equipo.camareros || 0) + "</td></tr>";
      tb.innerHTML += "<tr><td style='padding-left: 20px'>COSTE TOTAL BODEGUEROS</td><td style='color: #0086b3' colspan='2'>" + Math.round(+this.coste.datos.equipo.bodegueros || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td style='padding-left: 20px'>COSTE TOTAL COCINEROS</td><td style='color: #0086b3' colspan='2'>" + Math.round(+this.coste.datos.equipo.cocineros || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td style='padding-left: 20px'>COSTE TOTAL PLONGE</td><td style='color: #0086b3' colspan='2'>" + Math.round(+this.coste.datos.equipo.senoras || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td style='padding-left: 20px'>COSTE TOTAL OTROS</td><td style='color: #0086b3' colspan='2'>" + Math.round(+this.coste.datos.equipo.otros || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";

      t.appendChild(tb)
      eq.appendChild(t)
      wrapper.appendChild(eq)

      //COSTES F&B
      var fb = v.document.createElement('div')
      fb.style = "page-break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      fb.innerHTML = "<h1 style='margin: 0px;text-align: center;border-bottom:1px solid black;background-color:rgb(135, 135, 135)'>COSTES F&B</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width : 100%"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr><td>VENTAS F&B</td><td style='color: #0086b3'>" + Math.round(this.coste.datos.minuta.ventasFnB || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>100%</td></tr>";
      tb.innerHTML += "<tr style='border-bottom: 1px solid gray'><td><b>TOTAL COSTE F&B</b></td><td style='color: #0086b3'><b>" + Math.round((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>" + (Math.round((+this.coste.datos.FnB.pntCosteBeberageFijo + +this.coste.datos.FnB.pntCosteBeberage + +this.coste.datos.FnB.pntCosteFoodFijo + +this.coste.datos.FnB.pntCosteFood) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")) + "%</b></td></tr>";
      tb.innerHTML += "<tr><td>% COSTE FOOD - FIJO</td><td></td><td style='color: #b30000'>" + Math.round(this.coste.datos.FnB.pntCosteFoodFijo || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>% COSTE BEBERAGE - FIJO</td><td></td><td style='color: #b30000'>" + Math.round(this.coste.datos.FnB.pntCosteBeberageFijo || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>% COSTE FOOD - VARIABLE</td><td></td><td style='color: #b30000'>" + Math.round(this.coste.datos.FnB.pntCosteFood || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>% COSTE BEBERAGE - VARIABLE</td><td></td><td style='color: #b30000'>" + Math.round(this.coste.datos.FnB.pntCosteBeberage || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";

      t.appendChild(tb)
      fb.appendChild(t)
      wrapper.appendChild(fb)

      //TOTAL ALQUILER
      var alq = v.document.createElement('div')
      alq.style = "page-break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      alq.innerHTML = "<h1 style='margin: 0px;text-align: center;border-bottom:1px solid black;background-color:rgb(135, 135, 135)'>ALQUILER</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width : 100%"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr><td>VENTAS F&B</b></td><td style='color: #0086b3'><b>" + Math.round(this.coste.datos.minuta.ventasFnB || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>100%</td></tr>";
      tb.innerHTML += "<tr style='border-bottom:1px solid gray'><td><b>TOTAL ALQUILER</b></td><td style='color: #0086b3'><b>" + Math.round((+this.coste.datos.minuta.alquileresEspacio + +this.coste.datos.alquiler.comision) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>" + Math.round(((((+this.coste.datos.minuta.alquileresEspacio) || 0) + (this.coste.datos.alquiler.comision || 0)) / (this.coste.datos.minuta.ventasFnB || 1)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>";
      tb.innerHTML += "<tr><td>ALQUILER</td><td style='color: #0086b3'>" + Math.round((+this.coste.datos.minuta.alquileresEspacio) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      tb.innerHTML += "<tr><td>COMISIÓN (€)</td><td style='color: #0086b3'>" + Math.round(this.coste.datos.alquiler.comision || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";

      t.appendChild(tb)
      alq.appendChild(t)
      wrapper.appendChild(alq)

      //TOTAL CATERING
      var cat = v.document.createElement('div')
      cat.style = "page-break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      cat.innerHTML = "<h1 style='margin: 0px;text-align: center;border-bottom:1px solid black;background-color:rgb(135, 135, 135)'>CATERING</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width : 100%"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr><td>VENTAS F&B</b></td><td style='color: #0086b3'><b>" + Math.round(this.coste.datos.minuta.ventasFnB || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>100%</td></tr>";
      tb.innerHTML += "<tr style='border-bottom: 1px solid gray'><td><b>TOTAL CATERING</b></td><td style='color: #0086b3'><b>" + Math.round(this.coste.datos.minuta.servicioCatering != 0 ? ((+this.totalCatering + 500) || 0) : (this.totalCatering || 0)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>" + Math.round(((this.coste.datos.minuta.servicioCatering != 0 ? ((+this.totalCatering + 500) || 0) : (this.totalCatering || 0)) / (this.coste.datos.minuta.ventasFnB || 1)) * 100).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>";

      this.coste.datos.costesCatering.forEach(item => {
        if (item.precio != 0) tb.innerHTML += "<tr><td>" + item.title + "</td><td style='color: #0086b3'>" + Math.round(item.precio || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td></tr>";
      });
      if (this.coste.datos.minuta.servicioCatering != 0) tb.innerHTML += "<tr><td>FIJO AMORTIZACIÓN (ROTURAS, CL, AATT...)</td><td style='color: #0086b3'>500</td></tr>";

      t.appendChild(tb)
      cat.appendChild(t)
      wrapper.appendChild(cat)

      //TOTAL GENERALES
      var gg = v.document.createElement('div')
      gg.style = "page-break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      gg.innerHTML = "<h1 style='margin: 0px;text-align: center;border-bottom:1px solid black;background-color:rgb(135, 135, 135)'>GASTOS GENERALES</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse; width : 100%"
      var tb = v.document.createElement('tbody')
      tb.innerHTML += "<tr><td>VENTAS F&B</b></td><td style='color: #0086b3'><b>" + Math.round(this.coste.datos.minuta.ventasFnB || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</td><td style='color: #b30000'>100%</td></tr>";
      tb.innerHTML += "<tr style='border-bottom: 1px solid gray'><td><b>TOTAL GASTOS GENERALES</b></td><td style='color: #0086b3'><b>" + Math.round((Math.round(((+(+this.coste.datos.minuta.ventasFnB * +(+(+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "</b></td><td style='color: #b30000'><b>" + Math.round((+this.coste.datos.general.pntCosteFijo + +this.coste.datos.general.pntCosteVariable) || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>";
      tb.innerHTML += "<tr><td>% COSTE - FIJO</td><td></td><td style='color: #b30000'>" + Math.round(this.coste.datos.general.pntCosteFijo || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";
      tb.innerHTML += "<tr><td>% COSTE - VARIABLE</td><td></td><td style='color: #b30000'>" + Math.round(this.coste.datos.general.pntCosteVariable || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + "%</td></tr>";

      t.appendChild(tb)
      gg.appendChild(t)
      wrapper.appendChild(gg)

      v.document.write(wrapper.outerHTML)
      v.print()
      v.close()
    }
  },
  mounted(){
    this.checkCostes();
    this.calculateBadges();
    this.reduceVal();
  }
};
</script>

<style>
.v-superdense-input .v-input__slot {
  min-height: 25px !important;
}
.v-superdense-input .v-text-field__slot {
  min-height: 25px !important;
}
.v-slide-group__prev.v-slide-group__prev--disabled{
  display: none !important;
}
.active{
  max-height: 10000px;
}
.activent{
  max-height: 40px;
  color:rgb(135, 135, 135)
}
</style>